import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';
import { PrintNode_DatasourceService } from './PrintNode.datasource.index';
import { InventoryCounts_DatasourceService } from './InventoryCounts.datasource.index';
import { Reports_DatasourceService } from './Reports.datasource.index';
import { LocusRobotics_DatasourceService } from './LocusRobotics.datasource.index';

import { app_custom_ds_get_job_prioritizationService } from './app.datasource.index';
import { app_custom_ds_get_orders_ready_to_queueService } from './app.datasource.index';
import { app_custom_ds_get_orders_to_updateService } from './app.datasource.index';
import { app_custom_get_orderInfo_by_orderIdService } from './app.datasource.index';
import { app_custom_get_order_info_from_footprintService } from './app.datasource.index';
import { app_custom_get_reason_codesService } from './app.datasource.index';

@Injectable({ providedIn: 'root' })
export class app_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
    this.PrintNode = this.injector.get(PrintNode_DatasourceService);
    this.InventoryCounts = this.injector.get(InventoryCounts_DatasourceService);
    this.Reports = this.injector.get(Reports_DatasourceService);
    this.LocusRobotics = this.injector.get(LocusRobotics_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
    public PrintNode: PrintNode_DatasourceService;
    public InventoryCounts: InventoryCounts_DatasourceService;
    public Reports: Reports_DatasourceService;
    public LocusRobotics: LocusRobotics_DatasourceService;
  public app: app_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _custom_ds_get_job_prioritization: app_custom_ds_get_job_prioritizationService;
  public get custom_ds_get_job_prioritization(): app_custom_ds_get_job_prioritizationService {
    if(!this._custom_ds_get_job_prioritization) {
      this._custom_ds_get_job_prioritization = this.injector.get(app_custom_ds_get_job_prioritizationService);
    }
    return this._custom_ds_get_job_prioritization;
  }
  private _custom_ds_get_orders_ready_to_queue: app_custom_ds_get_orders_ready_to_queueService;
  public get custom_ds_get_orders_ready_to_queue(): app_custom_ds_get_orders_ready_to_queueService {
    if(!this._custom_ds_get_orders_ready_to_queue) {
      this._custom_ds_get_orders_ready_to_queue = this.injector.get(app_custom_ds_get_orders_ready_to_queueService);
    }
    return this._custom_ds_get_orders_ready_to_queue;
  }
  private _custom_ds_get_orders_to_update: app_custom_ds_get_orders_to_updateService;
  public get custom_ds_get_orders_to_update(): app_custom_ds_get_orders_to_updateService {
    if(!this._custom_ds_get_orders_to_update) {
      this._custom_ds_get_orders_to_update = this.injector.get(app_custom_ds_get_orders_to_updateService);
    }
    return this._custom_ds_get_orders_to_update;
  }
  private _custom_get_orderInfo_by_orderId: app_custom_get_orderInfo_by_orderIdService;
  public get custom_get_orderInfo_by_orderId(): app_custom_get_orderInfo_by_orderIdService {
    if(!this._custom_get_orderInfo_by_orderId) {
      this._custom_get_orderInfo_by_orderId = this.injector.get(app_custom_get_orderInfo_by_orderIdService);
    }
    return this._custom_get_orderInfo_by_orderId;
  }
  private _custom_get_order_info_from_footprint: app_custom_get_order_info_from_footprintService;
  public get custom_get_order_info_from_footprint(): app_custom_get_order_info_from_footprintService {
    if(!this._custom_get_order_info_from_footprint) {
      this._custom_get_order_info_from_footprint = this.injector.get(app_custom_get_order_info_from_footprintService);
    }
    return this._custom_get_order_info_from_footprint;
  }
  private _custom_get_reason_codes: app_custom_get_reason_codesService;
  public get custom_get_reason_codes(): app_custom_get_reason_codesService {
    if(!this._custom_get_reason_codes) {
      this._custom_get_reason_codes = this.injector.get(app_custom_get_reason_codesService);
    }
    return this._custom_get_reason_codes;
  }
}

