import { Inject, Injectable, Injector } from '@angular/core';


import { InventoryCounts_cycle_count_reportService } from './InventoryCounts.report.index';

@Injectable({ providedIn: 'root' })
export class InventoryCounts_ReportService {

  constructor(
    private injector: Injector
  ) {
  }

  public InventoryCounts: InventoryCounts_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _cycle_count_report: InventoryCounts_cycle_count_reportService;
  public get cycle_count_report(): InventoryCounts_cycle_count_reportService {
    if(!this._cycle_count_report) {
      this._cycle_count_report = this.injector.get(InventoryCounts_cycle_count_reportService);
    }
    return this._cycle_count_report;
  }
}

