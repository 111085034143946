import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './LocusRobotics.frontend.types'

@Injectable({ providedIn: 'root' })
export class LocusRobotics_create_packing_taskService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { jobId?: string, targetLocationId?: number, targetLicensePlate?: string, taskIds?: number[], warehouseId?: number, locationString?: string }): Promise<{ packingTaskId?: number, reasons?: string[], locationId?: number, warehouseId?: number }> 
  {
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

