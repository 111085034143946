import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';
import { PrintNode_FlowService } from './PrintNode.flow.index';
import { InventoryCounts_FlowService } from './InventoryCounts.flow.index';
import { Reports_FlowService } from './Reports.flow.index';
import { LocusRobotics_FlowService } from './LocusRobotics.flow.index';

import { app_custom_create_standalone_count_tasksService } from './app.flow.index';
import { app_custom_get_orders_to_queueService } from './app.flow.index';
import { app_custom_job_prioritization_flowService } from './app.flow.index';
import { app_custom_print_mainService } from './app.flow.index';
import { app_custom_update_orderJobsService } from './app.flow.index';

import { $frontendTypes } from './app.frontend.types'

@Injectable({ providedIn: 'root' })
export class app_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
    this.PrintNode = this.injector.get(PrintNode_FlowService);
    this.InventoryCounts = this.injector.get(InventoryCounts_FlowService);
    this.Reports = this.injector.get(Reports_FlowService);
    this.LocusRobotics = this.injector.get(LocusRobotics_FlowService);
  }

    public Utilities: Utilities_FlowService;
    public PrintNode: PrintNode_FlowService;
    public InventoryCounts: InventoryCounts_FlowService;
    public Reports: Reports_FlowService;
    public LocusRobotics: LocusRobotics_FlowService;
  public app: app_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _custom_create_standalone_count_tasks: app_custom_create_standalone_count_tasksService;
  public async custom_create_standalone_count_tasks(inParams: { warehouseId?: number, lotId?: number, expectedAmount?: number, projectId?: number, actualAmount?: number, expectedPackageId?: number, actualPackageId?: number, locationId?: number, materialId?: number, statusId?: number, vendorLotId?: number, jobId?: string, countTaskId?: number, licenseplateId?: number }): Promise< { newTaskId?: number, reasons?: string[], amountsEqual?: boolean }> {
    if(!this._custom_create_standalone_count_tasks) {
      this._custom_create_standalone_count_tasks = this.injector.get(app_custom_create_standalone_count_tasksService);
    }
    return this._custom_create_standalone_count_tasks.run(inParams);
  }
   
   

   
 
  private _custom_get_orders_to_queue: app_custom_get_orders_to_queueService;
  public async custom_get_orders_to_queue(inParams: { minimumDateThreshold?: string }): Promise< { output?: string[] }> {
    if(!this._custom_get_orders_to_queue) {
      this._custom_get_orders_to_queue = this.injector.get(app_custom_get_orders_to_queueService);
    }
    return this._custom_get_orders_to_queue.run(inParams);
  }
   
   

   
 
  private _custom_job_prioritization_flow: app_custom_job_prioritization_flowService;
  public async custom_job_prioritization_flow(inParams: { orderId?: number, containerSize?: string }): Promise< { jobPriority?: number }> {
    if(!this._custom_job_prioritization_flow) {
      this._custom_job_prioritization_flow = this.injector.get(app_custom_job_prioritization_flowService);
    }
    return this._custom_job_prioritization_flow.run(inParams);
  }
   
   

   
 
  private _custom_print_main: app_custom_print_mainService;
  public async custom_print_main(inParams: { content?: number, logId?: string, transactionId?: string, jobId?: string }): Promise< { base64LabelData?: string }> {
    if(!this._custom_print_main) {
      this._custom_print_main = this.injector.get(app_custom_print_mainService);
    }
    return this._custom_print_main.run(inParams);
  }
   
   

   
 
  private _custom_update_orderJobs: app_custom_update_orderJobsService;
  public async custom_update_orderJobs(inParams: { transactionId?: string }): Promise< { reasons?: string[] }> {
    if(!this._custom_update_orderJobs) {
      this._custom_update_orderJobs = this.injector.get(app_custom_update_orderJobsService);
    }
    return this._custom_update_orderJobs.run(inParams);
  }
   
   

   
}
