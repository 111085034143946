import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class SettingsValuesService {

  private _SettingsService: any = {};
  private _isInitialized = false;

  public set SettingsService(value: any) {
    this._isInitialized = true;
    this._SettingsService = value;
  }
  public get SettingsService() {
    return this._SettingsService;
  }

  public get isInitialized() {
    return this._isInitialized;
  }

  public get DateFormat() {
    return this._SettingsService['DateFormat'];
  }
  public get FootPrintAPI() {
    return this._SettingsService['FootPrintAPI'];
  }
  public get integration_name() {
    return this._SettingsService['integration_name'];
  }
  public get MongoDb() {
    return this._SettingsService['MongoDb'];
  }
  public get PrintNodeKey() {
    return this._SettingsService['PrintNodeKey'];
  }

  public app: SettingsValuesService = this;

  public get Utilities() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDb
    };
  }
  public get PrintNode() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDb,
PrintNodeKey : this.PrintNodeKey
    };
  }
  public get InventoryCounts() {
     return {
DateFormat : this.DateFormat,
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDb
    };
  }
  public get Reports() {
     return {
FootPrintAPI : this.FootPrintAPI,
MongoDB : this.MongoDb
    };
  }
  public get LocusRobotics() {
     return {
DateFormat : this.DateFormat,
FootPrintAPI : this.FootPrintAPI,
integration_name : this.integration_name,
MongoDB : this.MongoDb,
PrintNodeKey : this.PrintNodeKey
    };
  }
  public get BarcodeParsing() {
     return {
FootPrintAPI : this.FootPrintAPI
    };
  }
}
