import { Inject, Injectable, Injector }from '@angular/core';


import { InventoryCounts_ds_count_accuracy_widgetService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_count_in_progress_flow_widgetService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_count_task_editorService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_count_task_listService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_count_tasks_gridService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_count_tasks_no_filter_gridService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_cycle_count_report_detailsService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_cycle_count_report_recount_detailsService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_find_footprinteventsService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_get_count_tasks_by_chainHeadsService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_get_count_tasks_by_chainheadService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_get_inventory_count_all_fieldsService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_get_inventory_count_detailsService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_get_inventory_count_discrepanciesService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_get_inventory_count_discrepancies_singleService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_get_inventory_count_disrepancy_summaryService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_get_inventory_count_tasksService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_get_inventory_counts_by_warehouseIdsService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_get_licenseplatecontentsService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_get_released_footprint_event_by_inventoryCountIdService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_get_tasks_by_taskIdsService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_inventory_count_discrepancies_count_by_idService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_inventory_count_editorService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_inventory_count_statuses_ddService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_inventory_count_tasks_progress_widgetService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_inventory_counts_gridService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_inventory_counts_in_progress_widgetService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_inventory_counts_single_gridService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_location_selector_batchedService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_location_selector_gridService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_material_selector_batchedService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_material_selector_gridService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_material_statuses_ddService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_projects_ddService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_storage_categories_ddService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_storage_category_rules_ddService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_vendorlot_selector_batchedService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_vendorlot_selector_gridService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_warehouses_ddService } from './InventoryCounts.datasource.index';
import { InventoryCounts_ds_zones_ddService } from './InventoryCounts.datasource.index';

@Injectable({ providedIn: 'root' })
export class InventoryCounts_DatasourceService {

  constructor(
    private injector: Injector
  ) {
  }

  public InventoryCounts: InventoryCounts_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_count_accuracy_widget: InventoryCounts_ds_count_accuracy_widgetService;
  public get ds_count_accuracy_widget(): InventoryCounts_ds_count_accuracy_widgetService {
    if(!this._ds_count_accuracy_widget) {
      this._ds_count_accuracy_widget = this.injector.get(InventoryCounts_ds_count_accuracy_widgetService);
    }
    return this._ds_count_accuracy_widget;
  }
  private _ds_count_in_progress_flow_widget: InventoryCounts_ds_count_in_progress_flow_widgetService;
  public get ds_count_in_progress_flow_widget(): InventoryCounts_ds_count_in_progress_flow_widgetService {
    if(!this._ds_count_in_progress_flow_widget) {
      this._ds_count_in_progress_flow_widget = this.injector.get(InventoryCounts_ds_count_in_progress_flow_widgetService);
    }
    return this._ds_count_in_progress_flow_widget;
  }
  private _ds_count_task_editor: InventoryCounts_ds_count_task_editorService;
  public get ds_count_task_editor(): InventoryCounts_ds_count_task_editorService {
    if(!this._ds_count_task_editor) {
      this._ds_count_task_editor = this.injector.get(InventoryCounts_ds_count_task_editorService);
    }
    return this._ds_count_task_editor;
  }
  private _ds_count_task_list: InventoryCounts_ds_count_task_listService;
  public get ds_count_task_list(): InventoryCounts_ds_count_task_listService {
    if(!this._ds_count_task_list) {
      this._ds_count_task_list = this.injector.get(InventoryCounts_ds_count_task_listService);
    }
    return this._ds_count_task_list;
  }
  private _ds_count_tasks_grid: InventoryCounts_ds_count_tasks_gridService;
  public get ds_count_tasks_grid(): InventoryCounts_ds_count_tasks_gridService {
    if(!this._ds_count_tasks_grid) {
      this._ds_count_tasks_grid = this.injector.get(InventoryCounts_ds_count_tasks_gridService);
    }
    return this._ds_count_tasks_grid;
  }
  private _ds_count_tasks_no_filter_grid: InventoryCounts_ds_count_tasks_no_filter_gridService;
  public get ds_count_tasks_no_filter_grid(): InventoryCounts_ds_count_tasks_no_filter_gridService {
    if(!this._ds_count_tasks_no_filter_grid) {
      this._ds_count_tasks_no_filter_grid = this.injector.get(InventoryCounts_ds_count_tasks_no_filter_gridService);
    }
    return this._ds_count_tasks_no_filter_grid;
  }
  private _ds_cycle_count_report_details: InventoryCounts_ds_cycle_count_report_detailsService;
  public get ds_cycle_count_report_details(): InventoryCounts_ds_cycle_count_report_detailsService {
    if(!this._ds_cycle_count_report_details) {
      this._ds_cycle_count_report_details = this.injector.get(InventoryCounts_ds_cycle_count_report_detailsService);
    }
    return this._ds_cycle_count_report_details;
  }
  private _ds_cycle_count_report_recount_details: InventoryCounts_ds_cycle_count_report_recount_detailsService;
  public get ds_cycle_count_report_recount_details(): InventoryCounts_ds_cycle_count_report_recount_detailsService {
    if(!this._ds_cycle_count_report_recount_details) {
      this._ds_cycle_count_report_recount_details = this.injector.get(InventoryCounts_ds_cycle_count_report_recount_detailsService);
    }
    return this._ds_cycle_count_report_recount_details;
  }
  private _ds_find_footprintevents: InventoryCounts_ds_find_footprinteventsService;
  public get ds_find_footprintevents(): InventoryCounts_ds_find_footprinteventsService {
    if(!this._ds_find_footprintevents) {
      this._ds_find_footprintevents = this.injector.get(InventoryCounts_ds_find_footprinteventsService);
    }
    return this._ds_find_footprintevents;
  }
  private _ds_get_count_tasks_by_chainHeads: InventoryCounts_ds_get_count_tasks_by_chainHeadsService;
  public get ds_get_count_tasks_by_chainHeads(): InventoryCounts_ds_get_count_tasks_by_chainHeadsService {
    if(!this._ds_get_count_tasks_by_chainHeads) {
      this._ds_get_count_tasks_by_chainHeads = this.injector.get(InventoryCounts_ds_get_count_tasks_by_chainHeadsService);
    }
    return this._ds_get_count_tasks_by_chainHeads;
  }
  private _ds_get_count_tasks_by_chainhead: InventoryCounts_ds_get_count_tasks_by_chainheadService;
  public get ds_get_count_tasks_by_chainhead(): InventoryCounts_ds_get_count_tasks_by_chainheadService {
    if(!this._ds_get_count_tasks_by_chainhead) {
      this._ds_get_count_tasks_by_chainhead = this.injector.get(InventoryCounts_ds_get_count_tasks_by_chainheadService);
    }
    return this._ds_get_count_tasks_by_chainhead;
  }
  private _ds_get_inventory_count_all_fields: InventoryCounts_ds_get_inventory_count_all_fieldsService;
  public get ds_get_inventory_count_all_fields(): InventoryCounts_ds_get_inventory_count_all_fieldsService {
    if(!this._ds_get_inventory_count_all_fields) {
      this._ds_get_inventory_count_all_fields = this.injector.get(InventoryCounts_ds_get_inventory_count_all_fieldsService);
    }
    return this._ds_get_inventory_count_all_fields;
  }
  private _ds_get_inventory_count_details: InventoryCounts_ds_get_inventory_count_detailsService;
  public get ds_get_inventory_count_details(): InventoryCounts_ds_get_inventory_count_detailsService {
    if(!this._ds_get_inventory_count_details) {
      this._ds_get_inventory_count_details = this.injector.get(InventoryCounts_ds_get_inventory_count_detailsService);
    }
    return this._ds_get_inventory_count_details;
  }
  private _ds_get_inventory_count_discrepancies: InventoryCounts_ds_get_inventory_count_discrepanciesService;
  public get ds_get_inventory_count_discrepancies(): InventoryCounts_ds_get_inventory_count_discrepanciesService {
    if(!this._ds_get_inventory_count_discrepancies) {
      this._ds_get_inventory_count_discrepancies = this.injector.get(InventoryCounts_ds_get_inventory_count_discrepanciesService);
    }
    return this._ds_get_inventory_count_discrepancies;
  }
  private _ds_get_inventory_count_discrepancies_single: InventoryCounts_ds_get_inventory_count_discrepancies_singleService;
  public get ds_get_inventory_count_discrepancies_single(): InventoryCounts_ds_get_inventory_count_discrepancies_singleService {
    if(!this._ds_get_inventory_count_discrepancies_single) {
      this._ds_get_inventory_count_discrepancies_single = this.injector.get(InventoryCounts_ds_get_inventory_count_discrepancies_singleService);
    }
    return this._ds_get_inventory_count_discrepancies_single;
  }
  private _ds_get_inventory_count_disrepancy_summary: InventoryCounts_ds_get_inventory_count_disrepancy_summaryService;
  public get ds_get_inventory_count_disrepancy_summary(): InventoryCounts_ds_get_inventory_count_disrepancy_summaryService {
    if(!this._ds_get_inventory_count_disrepancy_summary) {
      this._ds_get_inventory_count_disrepancy_summary = this.injector.get(InventoryCounts_ds_get_inventory_count_disrepancy_summaryService);
    }
    return this._ds_get_inventory_count_disrepancy_summary;
  }
  private _ds_get_inventory_count_tasks: InventoryCounts_ds_get_inventory_count_tasksService;
  public get ds_get_inventory_count_tasks(): InventoryCounts_ds_get_inventory_count_tasksService {
    if(!this._ds_get_inventory_count_tasks) {
      this._ds_get_inventory_count_tasks = this.injector.get(InventoryCounts_ds_get_inventory_count_tasksService);
    }
    return this._ds_get_inventory_count_tasks;
  }
  private _ds_get_inventory_counts_by_warehouseIds: InventoryCounts_ds_get_inventory_counts_by_warehouseIdsService;
  public get ds_get_inventory_counts_by_warehouseIds(): InventoryCounts_ds_get_inventory_counts_by_warehouseIdsService {
    if(!this._ds_get_inventory_counts_by_warehouseIds) {
      this._ds_get_inventory_counts_by_warehouseIds = this.injector.get(InventoryCounts_ds_get_inventory_counts_by_warehouseIdsService);
    }
    return this._ds_get_inventory_counts_by_warehouseIds;
  }
  private _ds_get_licenseplatecontents: InventoryCounts_ds_get_licenseplatecontentsService;
  public get ds_get_licenseplatecontents(): InventoryCounts_ds_get_licenseplatecontentsService {
    if(!this._ds_get_licenseplatecontents) {
      this._ds_get_licenseplatecontents = this.injector.get(InventoryCounts_ds_get_licenseplatecontentsService);
    }
    return this._ds_get_licenseplatecontents;
  }
  private _ds_get_released_footprint_event_by_inventoryCountId: InventoryCounts_ds_get_released_footprint_event_by_inventoryCountIdService;
  public get ds_get_released_footprint_event_by_inventoryCountId(): InventoryCounts_ds_get_released_footprint_event_by_inventoryCountIdService {
    if(!this._ds_get_released_footprint_event_by_inventoryCountId) {
      this._ds_get_released_footprint_event_by_inventoryCountId = this.injector.get(InventoryCounts_ds_get_released_footprint_event_by_inventoryCountIdService);
    }
    return this._ds_get_released_footprint_event_by_inventoryCountId;
  }
  private _ds_get_tasks_by_taskIds: InventoryCounts_ds_get_tasks_by_taskIdsService;
  public get ds_get_tasks_by_taskIds(): InventoryCounts_ds_get_tasks_by_taskIdsService {
    if(!this._ds_get_tasks_by_taskIds) {
      this._ds_get_tasks_by_taskIds = this.injector.get(InventoryCounts_ds_get_tasks_by_taskIdsService);
    }
    return this._ds_get_tasks_by_taskIds;
  }
  private _ds_inventory_count_discrepancies_count_by_id: InventoryCounts_ds_inventory_count_discrepancies_count_by_idService;
  public get ds_inventory_count_discrepancies_count_by_id(): InventoryCounts_ds_inventory_count_discrepancies_count_by_idService {
    if(!this._ds_inventory_count_discrepancies_count_by_id) {
      this._ds_inventory_count_discrepancies_count_by_id = this.injector.get(InventoryCounts_ds_inventory_count_discrepancies_count_by_idService);
    }
    return this._ds_inventory_count_discrepancies_count_by_id;
  }
  private _ds_inventory_count_editor: InventoryCounts_ds_inventory_count_editorService;
  public get ds_inventory_count_editor(): InventoryCounts_ds_inventory_count_editorService {
    if(!this._ds_inventory_count_editor) {
      this._ds_inventory_count_editor = this.injector.get(InventoryCounts_ds_inventory_count_editorService);
    }
    return this._ds_inventory_count_editor;
  }
  private _ds_inventory_count_statuses_dd: InventoryCounts_ds_inventory_count_statuses_ddService;
  public get ds_inventory_count_statuses_dd(): InventoryCounts_ds_inventory_count_statuses_ddService {
    if(!this._ds_inventory_count_statuses_dd) {
      this._ds_inventory_count_statuses_dd = this.injector.get(InventoryCounts_ds_inventory_count_statuses_ddService);
    }
    return this._ds_inventory_count_statuses_dd;
  }
  private _ds_inventory_count_tasks_progress_widget: InventoryCounts_ds_inventory_count_tasks_progress_widgetService;
  public get ds_inventory_count_tasks_progress_widget(): InventoryCounts_ds_inventory_count_tasks_progress_widgetService {
    if(!this._ds_inventory_count_tasks_progress_widget) {
      this._ds_inventory_count_tasks_progress_widget = this.injector.get(InventoryCounts_ds_inventory_count_tasks_progress_widgetService);
    }
    return this._ds_inventory_count_tasks_progress_widget;
  }
  private _ds_inventory_counts_grid: InventoryCounts_ds_inventory_counts_gridService;
  public get ds_inventory_counts_grid(): InventoryCounts_ds_inventory_counts_gridService {
    if(!this._ds_inventory_counts_grid) {
      this._ds_inventory_counts_grid = this.injector.get(InventoryCounts_ds_inventory_counts_gridService);
    }
    return this._ds_inventory_counts_grid;
  }
  private _ds_inventory_counts_in_progress_widget: InventoryCounts_ds_inventory_counts_in_progress_widgetService;
  public get ds_inventory_counts_in_progress_widget(): InventoryCounts_ds_inventory_counts_in_progress_widgetService {
    if(!this._ds_inventory_counts_in_progress_widget) {
      this._ds_inventory_counts_in_progress_widget = this.injector.get(InventoryCounts_ds_inventory_counts_in_progress_widgetService);
    }
    return this._ds_inventory_counts_in_progress_widget;
  }
  private _ds_inventory_counts_single_grid: InventoryCounts_ds_inventory_counts_single_gridService;
  public get ds_inventory_counts_single_grid(): InventoryCounts_ds_inventory_counts_single_gridService {
    if(!this._ds_inventory_counts_single_grid) {
      this._ds_inventory_counts_single_grid = this.injector.get(InventoryCounts_ds_inventory_counts_single_gridService);
    }
    return this._ds_inventory_counts_single_grid;
  }
  private _ds_location_selector_batched: InventoryCounts_ds_location_selector_batchedService;
  public get ds_location_selector_batched(): InventoryCounts_ds_location_selector_batchedService {
    if(!this._ds_location_selector_batched) {
      this._ds_location_selector_batched = this.injector.get(InventoryCounts_ds_location_selector_batchedService);
    }
    return this._ds_location_selector_batched;
  }
  private _ds_location_selector_grid: InventoryCounts_ds_location_selector_gridService;
  public get ds_location_selector_grid(): InventoryCounts_ds_location_selector_gridService {
    if(!this._ds_location_selector_grid) {
      this._ds_location_selector_grid = this.injector.get(InventoryCounts_ds_location_selector_gridService);
    }
    return this._ds_location_selector_grid;
  }
  private _ds_material_selector_batched: InventoryCounts_ds_material_selector_batchedService;
  public get ds_material_selector_batched(): InventoryCounts_ds_material_selector_batchedService {
    if(!this._ds_material_selector_batched) {
      this._ds_material_selector_batched = this.injector.get(InventoryCounts_ds_material_selector_batchedService);
    }
    return this._ds_material_selector_batched;
  }
  private _ds_material_selector_grid: InventoryCounts_ds_material_selector_gridService;
  public get ds_material_selector_grid(): InventoryCounts_ds_material_selector_gridService {
    if(!this._ds_material_selector_grid) {
      this._ds_material_selector_grid = this.injector.get(InventoryCounts_ds_material_selector_gridService);
    }
    return this._ds_material_selector_grid;
  }
  private _ds_material_statuses_dd: InventoryCounts_ds_material_statuses_ddService;
  public get ds_material_statuses_dd(): InventoryCounts_ds_material_statuses_ddService {
    if(!this._ds_material_statuses_dd) {
      this._ds_material_statuses_dd = this.injector.get(InventoryCounts_ds_material_statuses_ddService);
    }
    return this._ds_material_statuses_dd;
  }
  private _ds_projects_dd: InventoryCounts_ds_projects_ddService;
  public get ds_projects_dd(): InventoryCounts_ds_projects_ddService {
    if(!this._ds_projects_dd) {
      this._ds_projects_dd = this.injector.get(InventoryCounts_ds_projects_ddService);
    }
    return this._ds_projects_dd;
  }
  private _ds_storage_categories_dd: InventoryCounts_ds_storage_categories_ddService;
  public get ds_storage_categories_dd(): InventoryCounts_ds_storage_categories_ddService {
    if(!this._ds_storage_categories_dd) {
      this._ds_storage_categories_dd = this.injector.get(InventoryCounts_ds_storage_categories_ddService);
    }
    return this._ds_storage_categories_dd;
  }
  private _ds_storage_category_rules_dd: InventoryCounts_ds_storage_category_rules_ddService;
  public get ds_storage_category_rules_dd(): InventoryCounts_ds_storage_category_rules_ddService {
    if(!this._ds_storage_category_rules_dd) {
      this._ds_storage_category_rules_dd = this.injector.get(InventoryCounts_ds_storage_category_rules_ddService);
    }
    return this._ds_storage_category_rules_dd;
  }
  private _ds_vendorlot_selector_batched: InventoryCounts_ds_vendorlot_selector_batchedService;
  public get ds_vendorlot_selector_batched(): InventoryCounts_ds_vendorlot_selector_batchedService {
    if(!this._ds_vendorlot_selector_batched) {
      this._ds_vendorlot_selector_batched = this.injector.get(InventoryCounts_ds_vendorlot_selector_batchedService);
    }
    return this._ds_vendorlot_selector_batched;
  }
  private _ds_vendorlot_selector_grid: InventoryCounts_ds_vendorlot_selector_gridService;
  public get ds_vendorlot_selector_grid(): InventoryCounts_ds_vendorlot_selector_gridService {
    if(!this._ds_vendorlot_selector_grid) {
      this._ds_vendorlot_selector_grid = this.injector.get(InventoryCounts_ds_vendorlot_selector_gridService);
    }
    return this._ds_vendorlot_selector_grid;
  }
  private _ds_warehouses_dd: InventoryCounts_ds_warehouses_ddService;
  public get ds_warehouses_dd(): InventoryCounts_ds_warehouses_ddService {
    if(!this._ds_warehouses_dd) {
      this._ds_warehouses_dd = this.injector.get(InventoryCounts_ds_warehouses_ddService);
    }
    return this._ds_warehouses_dd;
  }
  private _ds_zones_dd: InventoryCounts_ds_zones_ddService;
  public get ds_zones_dd(): InventoryCounts_ds_zones_ddService {
    if(!this._ds_zones_dd) {
      this._ds_zones_dd = this.injector.get(InventoryCounts_ds_zones_ddService);
    }
    return this._ds_zones_dd;
  }
}

