import { Inject, Injectable, Injector } from '@angular/core';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';

import { Utilities_OperationService } from './Utilities.operation.service';
import { PrintNode_OperationService } from './PrintNode.operation.service';
import { InventoryCounts_OperationService } from './InventoryCounts.operation.service';
import { Reports_OperationService } from './Reports.operation.service';
import { LocusRobotics_OperationService } from './LocusRobotics.operation.service';


@Injectable({ providedIn: 'root' })
export class app_OperationService {

  constructor(
    private injector: Injector,
    private utils: UtilsService,
  ) {
    this.Utilities = this.injector.get(Utilities_OperationService);
    this.PrintNode = this.injector.get(PrintNode_OperationService);
    this.InventoryCounts = this.injector.get(InventoryCounts_OperationService);
    this.Reports = this.injector.get(Reports_OperationService);
    this.LocusRobotics = this.injector.get(LocusRobotics_OperationService);
  }

    public Utilities: Utilities_OperationService;
    public PrintNode: PrintNode_OperationService;
    public InventoryCounts: InventoryCounts_OperationService;
    public Reports: Reports_OperationService;
    public LocusRobotics: LocusRobotics_OperationService;
  public app: app_OperationService = this;


  private isAuthorized(operationName: string): Promise<boolean> {
    return this.utils.http.post(`${environment.backendUrl}api/app/operations/${operationName}/isauthorized`, null);
  }
}
