import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { InventoryCounts_homeComponent } from './InventoryCounts.home.component';
import { InventoryCounts_cycle_count_reportComponent } from './InventoryCounts.cycle_count_report.component';


@Injectable({ providedIn: 'root' })
export class InventoryCounts_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
  ) {
    super(dialog, toastr);
  }

  public InventoryCounts: InventoryCounts_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openhome(replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'InventoryCounts',
        referenceName: 'InventoryCounts_home',
        component: InventoryCounts_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openhomeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      InventoryCounts_homeComponent,
      'InventoryCounts',
      mode,
      dialogSize
    )
  }
  public opencycle_count_report(inParams:{ cycle_count_id: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Cycle Count Report',
        referenceName: 'InventoryCounts_cycle_count_report',
        component: InventoryCounts_cycle_count_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencycle_count_reportDialog(
    inParams:{ cycle_count_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      InventoryCounts_cycle_count_reportComponent,
      'Cycle Count Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'InventoryCounts_home') {
      const title = 'InventoryCounts';
      const component = InventoryCounts_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryCounts_cycle_count_report') {
      const title = 'Cycle Count Report';
      const component = InventoryCounts_cycle_count_reportComponent;
      const inParams:{ cycle_count_id: number } = { cycle_count_id: null };
      if (!isNil(params.get('cycle_count_id'))) {
        const paramValueString = params.get('cycle_count_id');
        inParams.cycle_count_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    return result;
  }
}
